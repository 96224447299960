/**
 * Machine used to spawn data actors used throughout the app.
 * @typedef SweftDataMachine
 * @type {StateMachine<SweftDataMachineContext, SweftDataMachineEvent>}
 */

/**
 * @interface SweftDataMachineDataActor
 * @prop {string} type
 * @prop {ActorRefFrom<T>} ref
 */

/**
 * @interface SweftDataMachineUnknownDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {'unknownDataActor'} type
 * @prop {ActorRefFrom<SweftDataUnknownDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineEntitySchemaDataActor
 */

/**
 * @interface SweftDataMachineEntitySchemaDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {'entitySchemaDataActor'} type
 * @prop {ActorRefFrom<SweftDataMachineEntitySchemaDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineAttributeSchemaDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {'attributeSchemaDataActor'} type
 * @prop {ActorRefFrom<SweftDataMachineAttributeSchemaDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineEntityDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {"entity"} type
 * @prop {string} entity
 * @prop {ActorRefFrom<SweftDataMachineEntitySchemaDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineContext
 * @prop {null |SweftDataMachineUnknownDataActor} unknownDataActor
 * @prop {null | SweftDataMachineEntitySchemaDataActor} entitySchemaDataActor
 * @prop {Array<SweftDataMachineEntityDataActor>} entityDataActorList
 */

/**
 * @typedef SweftDataMachineEvent
 * @type {{ type: 'COMMAND_SPAWN_ENTITY_DATA_ACTOR'; entity: string }}
 */
import { assign, createMachine, spawn, actions } from "xstate";

const { send, pure } = actions;
import { unknownDataActorMachine } from "@app/data/machine/actors/unknownDataActorMachine";

import {
    generateDataActorId
} from "@app/data/machine/utils";

import { findInList } from "@app/common/utils";
import { dataActorBuilder } from "@app/data/machine/actors/builder";
import { alertsDataActorMachine } from "@app/data/machine/actors/alertsDataActor";


/**
 * @type {SweftDataMachine}
 */
export const dataMachine = createMachine({
    id: "dataMachine",
    context: {
        alertsDataActor: null,
        unknownDataActor: null,
        entityDataActorList: [],
        dataActorList: [],
        dataLoadedList: [],
    },
    initial: "init",
    states: {
        init: {
            entry: "spawnInitialActors",
            always: "listening",
        },
        listening: {
            on: {
                COMMAND_SPAWN_DATA_ACTOR: {
                    actions: "spawnDataActor",
                    cond: "isNewDataActor"
                },
                DATA_LOADED: {
                    actions: "addToDataLoadedList"
                }
            }
        }
    }
},
{
    actions: {
        spawnInitialActors: assign({
            unknownDataActor: () => ({
                type: "unknownDataActor",
                ref: spawn(unknownDataActorMachine)
            }),
            alertsDataActor: () => ({
                type: "alertsDataActor",
                ref: spawn(alertsDataActorMachine)
            }),
        }),
        spawnDataActor: assign({
            dataActorList: (context, event) => {
                const { dataActorOptions } = event;
                const dataActorObj = dataActorBuilder({ dataActorOptions });
                if (!dataActorObj.ref) {
                    return context.dataActorList;
                }
                return context.dataActorList.concat({
                    ...dataActorObj,
                    ref: spawn(dataActorObj.ref)
                });
            }
        }),
        forwardEventToAllDataActors: pure((context, event) => {
            return Object.keys(context.dataActorList).map((actorKey) => {
                return send(event, { to: context.dataActorList[actorKey].ref });
            });
        }),
        addToDataLoadedList: assign({
            dataLoadedList: (context, event) => {
                const { dataType } = event;
                return Array.from(new Set([...context.dataLoadedList, dataType]));
            }
        })
    },
    guards: {
        isNewDataActor: (context, event) => {
            const { dataActorOptions } = event;
            const dataActorId = generateDataActorId({ dataActorOptions });
            const dataActorAlreadyExists = findInList(context.dataActorList, { dataActorId });
            return !dataActorAlreadyExists;
        },
    }
});
