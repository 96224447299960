import { createMachine, assign, sendParent } from "xstate";

/**
 * A machine for calling a createService and passing in an object to update via the context
 *
 * @param context - The object that will be created
 * @param createMachineOptions - Machine options (actions, services, guards)
 * @returns {StateMachine}
 */
export const generateCreateDataMachine = ({ context: machineContext, createMachineOptions }) => {
    return createMachine({
        predictableActionArguments: true,
        id: `createData`,
        context: machineContext,
        initial: "checkIfGenIdIsNeeded",
        states: {
            checkIfGenIdIsNeeded: {
                always: [
                    {
                        target: "gettingGeneratedIdValuesForObject",
                        cond: "objectNeedsGeneratedIdValues"
                    },
                    {
                        target: "callingCreateService"
                    }
                ]
            },
            gettingGeneratedIdValuesForObject: {
                invoke: {
                    src: "generatedIdService",
                    onDone: {
                        actions: ["setObjectInContext", "sendToParentTransientObjectWithGeneratedId"],
                        target: 'callingCreateService',
                    },
                },
            },
            callingCreateService: {
                invoke: {
                    src: "createService",
                    onDone: {
                        target: 'done',
                    },
                },
            },
            done: {
                type: "final"
            }
        }
    }, {
        guards: {
            objectNeedsGeneratedIdValues: (context) => context?.gidAttributesList?.length > 0
        },
        actions: {
            setObjectInContext: assign({
                object: (context, event) => {
                    return event?.data?.newObject || context.object;
                }
            }),
            sendToParentTransientObjectWithGeneratedId: sendParent((context, event) => ({ type: "GENERATED_TRANSIENT_OBJECT_UPDATED", transientObject: event?.data?.newObject ?? context.object, fieldBeingUpdated: event.generatedIdObject }))
        },
        ...createMachineOptions
    });
};
