import { generateEntityDataMachineGeneratorProps } from "@app/data/machine/actors/entityDataActorMachine";
import { VanguardService } from "@app/services/vanguardService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";

const generateEntitySavedQueryDataMachineGeneratorProps = ({ dataActorOptions }) => {
    const { type = "ENTITY_SAVED_QUERY_BUSINESS_OBJECTS" } = dataActorOptions;
    const entityDataMachineProps = generateEntityDataMachineGeneratorProps({ dataActorOptions });

    return {
        ...entityDataMachineProps,
        type,
    };
};

export const generateEntitySavedQueryDataActorMachine = ({ dataActorOptions }) => generateOptimisticDataMachine(generateEntitySavedQueryDataMachineGeneratorProps({ dataActorOptions }));
