/**
 * ------------------------------------------------------------
 * Selector:
 * A function that takes in an actor's "current state" (snapshot)
 * as an argument and returns the desired selected value.
 * Selectors are used with the useSelector hook.
 * ------------------------------------------------------------
 */

import { getUniqueObjList } from "@app/common/utils";


/**
 * Evaluates all the formula fields in each bobj
 * If the bobj has already been evaluated, and the formula attributes are removed,
 * revaluate the formula from the formulaAttributeMapByAttributeId
 */
export const evaluatedDataSelector = (state) => {
    if (state.value.loader !== "idle") {
        return [];
    }
    const { keyProperty } = state?.context ?? "id";
    let data = [];
    try {
        data = state?.context?.evaluatedData?.map((obj) => obj.data || obj);
    } catch (e) {
        const machineId = state?.machine?.id;
        console.warn(`Error in mapping the loaded data from machine ${machineId}`);
    }

    const transientData = state?.context?.transientData || [];

    const newData = data;

    transientData.forEach(({ object }) => {
        const indexOnData = data.findIndex((bobj) => bobj[keyProperty] === object[keyProperty]);
        if (indexOnData > -1) {
            data[indexOnData] = object;
        } else {
            newData.unshift(object);
        }
    });

    return getUniqueObjList({ objectList: newData, keyProperty });
};

