import React, { useEffect } from "react";
import { DashboardModule } from "@app/dashboard/controls/DashboardModule";
import { useMessages } from "@app/contexts/useMessages";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import { Badge } from "antd";
import { RenderListOfMessages } from "@app/grid/renderers/controls/gridMessageModal/renderMessages";

/**
 * Use to display a subsection of messages on the dashboard,
 * and navigate to the product/entity where the message was created
 * @returns {JSX.Element}
 */
export const DashboardModuleMessages = () => {
    const { messageListForUserDashboard, getUnreadMessagesForUser } = useMessages();
    const navigate = useNavigate();
    const { findWorkspaceObjBasedOnId } = useWorkspaces();

    useEffect(() => {
        getUnreadMessagesForUser();
    }, []);

    const redirectToWorkspace = (item) => {
        const filterMessageRowsForWorkspace = item.objectId;
        const productWorkspaceObj = findWorkspaceObjBasedOnId("product");
        const getWorkspacePath = productWorkspaceObj?.specifications?.path;
        if (!getWorkspacePath) {
            throw Error('Workspace path not found');
        } else {
            navigate({
                pathname: getWorkspacePath,
                search: `?${createSearchParams({ filterMessageRowsForWorkspace })}`
            });
        }
    };
    return (
        <DashboardModule className="col-start-8 col-end-13 row-span-3">
            <DashboardModule.Header>
                <div className="message-icon-dashboard">
                    <Badge showZero count={messageListForUserDashboard.length} overflowCount={99} style={{ color: '#000000', background: '#00000000', boxShadow: 'none' }}/>
                </div>
                <DashboardModule.Title>My Messages</DashboardModule.Title>
            </DashboardModule.Header>
            <DashboardModule.Body>
                {
                    messageListForUserDashboard.length > 0 &&
                    <RenderListOfMessages messageListForSingleObject={messageListForUserDashboard} redirectToWorkspace={redirectToWorkspace}/>
                }
            </DashboardModule.Body>
        </DashboardModule>
    );
};
