import React from "react";
import { DashboardModuleAlertsComponent } from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleAlertsComponent";
import { useAlerts } from "@app/contexts/useAlerts";
import { useAlertStatus } from "@app/contexts/useAlerts/useAlertStatus";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { SavedQueryAlertsWrapper } from "@app/contexts/useAlerts/context";


export const DashboardModuleAlertsContainer = () =>
    <SavedQueryAlertsWrapper>
        <DashboardModuleAlertsComponent useAlerts={useAlerts} useAlertStatus={useAlertStatus} useSpecialty={useSpecialty}/>
    </SavedQueryAlertsWrapper>;
