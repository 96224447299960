import React, { useCallback, useEffect, useMemo } from "react";
import { AlertsContext } from "@app/contexts/useAlerts/context";
import { useData } from "@app/data/context";
import { useActor, useSelector } from "@xstate/react";
import { isEqual } from "lodash";
import { useCurrentUserRole } from "@app/auth/useCurrentUserRole";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";


const alertsDataActorObjSelector = (state) => state.context.alertsDataActor;


export const SavedQueryAlertsContextProvider = ({ children }) => {
    const { specialty } = useSpecialty();
    const { dataMachineService } = useData();
    const { currentUserRole } = useCurrentUserRole();
    const { findWorkspaceObjBasedOnId } = useWorkspaces();
    const alertsDataActorObj = useSelector(dataMachineService, alertsDataActorObjSelector, isEqual);
    const [alertsDataActorState, alertsDataActorSend] = useActor(alertsDataActorObj.ref);

    useEffect(() => {
        if (currentUserRole) {
            alertsDataActorSend({ type: "USER_ROLE_CHANGED", nextUserRole: currentUserRole });
        }
    }, [alertsDataActorSend, currentUserRole]);

    useEffect(() => {
        if (specialty?.config) {
            alertsDataActorSend({ type: "USER_SPECIALTY_CHANGED", nextUserSpecialty: specialty?.config });
        }
    }, [alertsDataActorSend, specialty?.config]);

    const { userAlertListByEntityMap, savedQueryIdByAlertIdMap, userSpecialty } = alertsDataActorState.context;

    const alerts = useMemo(() => Object.values(userAlertListByEntityMap).flat().map((alertObj) => {
        const workspaceObj = findWorkspaceObjBasedOnId(alertObj.workspace);
        let additionalJsonLogicQueryObject = null;
        if (specialty?.isEnabled) {
            const alertWorkspaceSpecialty = userSpecialty?.find((workspaceSpecialtyObj) => workspaceSpecialtyObj.workspace = alertObj.workspace);
            additionalJsonLogicQueryObject = alertWorkspaceSpecialty?.savedQueryObject ?? null;
        }
        const savedQueryId = savedQueryIdByAlertIdMap[alertObj.alertId];
        return {
            ...alertObj,
            savedQueryId,
            path: `${workspaceObj?.specifications?.path}?alert=${alertObj.alertId}&savedQueryId=${savedQueryId}`,
            additionalJsonLogicQueryObject
        };
    }), [userAlertListByEntityMap, savedQueryIdByAlertIdMap, specialty?.isEnabled, userSpecialty]);

    const getAlertById = useCallback((alertId) => {
        return alerts?.find((alertObj) => alertObj.alertId === alertId);
    }, [alerts]);

    /**
     * @type {AlertsContextValue}
     */
    const contextValue = useMemo(() => ({
        alerts,
        getAlertById
    }), [alerts, getAlertById]);
    return (
        <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>
    );
};
