/**
 * @typedef {Object} SweftOptimisticDataMachineServices
 * @property {function(SweftOptimisticDataMachineContext,SweftOptimisticDataMachineEvent):{ loadedData: Array<Object>}} loadService <b>Assign your event handler</b>
 */
import { isObject } from "@app/common/utils";

export const checkIfFreshObjectHasTransientChangedData = ({ potentiallyFreshDataObj, transientChangeDataObj, transientConfig }) => {
    if (!isObject(transientChangeDataObj)) {
        return false;
    }
    return Object.keys(transientChangeDataObj).every((attribute) => {
        // The property is a many relationship attribute
        if (Array.isArray(potentiallyFreshDataObj[attribute])) {
            if (Array.isArray(transientChangeDataObj[attribute])) {
                if (potentiallyFreshDataObj[attribute].length === transientChangeDataObj[attribute].length) {
                    const freshRelatedObjList = potentiallyFreshDataObj[attribute].map((idOrObj) => {
                        if (typeof idOrObj === "string") {
                            return idOrObj;
                        }
                        if (isObject(idOrObj)) {
                            return idOrObj.id;
                        }
                        return null;
                    });
                    return transientChangeDataObj[attribute].map((idOrObj) => {
                        if (typeof idOrObj === "string") {
                            return idOrObj;
                        }
                        if (isObject(idOrObj)) {
                            return idOrObj.id;
                        }
                        return null;
                    }).every((relatedId) => freshRelatedObjList.includes(relatedId));
                }
                // Not the same length so one is out of sync
                return false;
            }
            // If the transient value isn't an array then the wrong type of value is being received
            return false;
        }

        // The property is a single relationship attribute
        if (isObject(potentiallyFreshDataObj[attribute])) {
            const freshRelatedId = potentiallyFreshDataObj[attribute].id;
            if (isObject(transientChangeDataObj[attribute])) {
                return freshRelatedId === transientChangeDataObj[attribute].id;
            }
            return freshRelatedId === transientChangeDataObj[attribute];
        }

        // Check if transient stamp is fresh
        if (attribute === transientConfig.transientProperty) {
            return transientChangeDataObj[transientConfig.transientProperty] >= transientChangeDataObj[transientConfig.transientProperty];
        }

        return potentiallyFreshDataObj[attribute] === transientChangeDataObj[attribute];
    });
};

export const optimisticDataActorMachineServicesBuilder = ({ servicesConfig, transientConfig }) => {
    const { loadDataFilteredForTransientData, ...restOfServicesConfig } = servicesConfig;
    const loadService = () => {
        console.warn("Default loadService called, check machine configuration.");
        return {
            loadedData: []
        };
    };

    return {
        loadService,
        loadDataFilteredForTransientData: async (context, event) => {
            const { transientData, keyProperty, transientChangeDataMap } = context;

            const { loadedData } = await loadDataFilteredForTransientData(context, event);
            const freshData = loadedData.filter((loadedDataObj) => {
                // Some services return the data in the root of the object
                // Some services return the data in a data property
                // Get this working for both
                const potentiallyFreshDataObj = loadedDataObj.data ? loadedDataObj.data : loadedDataObj;

                // Try and find this loadedObject in transientData
                const foundTransientDataObjFull = transientData.find(
                    ({ object: transientDataObj }) => transientDataObj[keyProperty] === potentiallyFreshDataObj[keyProperty]
                );

                // If the loaded object is not represented in transient data
                // then it is not meant to be processed now
                if (!foundTransientDataObjFull) {
                    return false;
                }

                // If the loaded object does not have a transientStamp
                // Then it is not meant to be processed now
                if (!potentiallyFreshDataObj[transientConfig.transientProperty]) {
                    return false;
                }

                const { object: transientChangeDataObj } = transientChangeDataMap[potentiallyFreshDataObj[keyProperty]];

                // If the fresh object has transient changed data
                // Then the transient object propagated and this loadedObject is the fresh one
                return checkIfFreshObjectHasTransientChangedData({ potentiallyFreshDataObj, transientChangeDataObj, transientConfig });
            });
            return { freshData };
        },
        ...restOfServicesConfig
    };
};
