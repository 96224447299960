// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import LeftNavModalContainer from './leftNavModalContainer';
import { useAuth } from "@app/auth/context/useAuth";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import classNames from "classnames";
import { baseConfig } from '@app/config/baseConfig';
import { LogoutOutlined, QuestionCircleOutlined, SecurityScanOutlined, UserSwitchOutlined } from '@ant-design/icons';
import './leftNav.less';
import { Link } from "react-router-dom";

export const logo_url = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//assets.${baseConfig.GATEWAY_API_BASE}/other/client-logo`;

export const UserMenu = ({ logout, triggerModalOpen, openHelpAsPDF }) => {
    const modalOpenHandler = (modalName) => (e) => {
        triggerModalOpen(modalName);
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <nav className="user-menu">
            <span className="arrow left" />
            <ul className="dropdown-list">
                <li role='menuitem' onClick={modalOpenHandler('profile')} data-modaltype="profile">
                    <a href="#">
                        <UserSwitchOutlined />{' '}
                        <span className="role">Change Role</span>
                    </a>
                </li>
                <li role='menuitem' onClick={modalOpenHandler('requestResetPassword')} data-modaltype="requestResetPassword">
                    <a href="#">
                        <SecurityScanOutlined />{' '}
                        <span className="role">Reset Password</span>
                    </a>
                </li>
                <li role='menuitem' onClick={openHelpAsPDF}>
                    <a href="#">
                        <QuestionCircleOutlined />{' '}
                        <span className="help">Help</span>
                    </a>
                </li>
                <li role='menuitem' onClick={(e) => {
                    logout(null);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                >
                    <a href="/logout">
                        <LogoutOutlined />{' '}
                        <span>Signout</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};


/**
 *
 */
const LeftNavComponent = ({
    modalClose,
    props,
    isModalOpen,
    modalName,
    triggerModalOpen,
    menuItems,
}) => {
    const { user, logout } = useAuth();
    const { currentWorkspace } = useWorkspaces();
    const logoImage = require('./images/Sweftlogo.svg');
    const getHelpPDF = require('./helpPdf/User-Guide-for-HRI.pdf');

    const openHelpAsPDF = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(getHelpPDF, '_blank');
    };

    return (
        <nav>
            <div>
                <LeftNavModalContainer
                    isOpen={isModalOpen}
                    modalClose={modalClose}
                    modalName={modalName}
                    user={user}
                    userId={user?.userId}
                    {...props}
                />
                <div className="left-nav-container">
                    <div className="text-center scroll flex flex-col h-full justify-between">
                        <div className="main-nav" role="navigation" aria-label='Main Navigation'>
                            <Link className="flex justify-center" to="/dashboard">
                                <img
                                    alt="Sweft"
                                    src={logoImage}
                                />
                            </Link>
                            <div>
                                <div role='menu' className="user-details" data-testid="userDropDown">
                                    <p className="user-name my-4">
                                        <a href="#" className="flex justify-start px-4 bg-teal-200 bg-opacity-10 text-gray-600">
                                            Hi,
                                            <span className="span-user-name pl-2" id="userName" data-testid="userName">
                                                {user?.nickName || user?.firstName}
                                            </span>
                                        </a>
                                    </p>
                                    <div className="dropdown-menu">
                                        <UserMenu logout={logout} triggerModalOpen={triggerModalOpen} openHelpAsPDF={openHelpAsPDF} />
                                    </div>
                                </div>
                            </div>
                            <ul className="menuList">
                                {menuItems?.map((item) => {
                                    return (
                                        <li key={item.text} className={classNames(`${item.text.toLowerCase()}`, { 'active': currentWorkspace?.id === item.id })}>
                                            <Link to={item.linkTo} title={item.text} className="reset" >
                                                <i className={item.icon} />
                                                <span className={`${currentWorkspace?.id === item.id ? 'text-white' : 'text-gray-600'}`}>{item.text}</span>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="py-8">
                            <img
                                alt="Upload Logo"
                                className="w-full h-auto"
                                src={logo_url}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

LeftNavComponent.propTypes = {
    modalClose: PropTypes.func,
    props: PropTypes.object,
    toggleListItemClassName: PropTypes.func,
    triggerModalOpen: PropTypes.func,
    user: PropTypes.object,
};

LeftNavComponent.defaultProps = {
    props: {},
};

export default LeftNavComponent;
