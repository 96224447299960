import { assign, createMachine, spawn } from "xstate";
import { unknownDataActorMachine } from "@app/data/machine/actors/unknownDataActorMachine";
import { entitySchemaDataActorMachine } from "@app/data/machine/actors/entitySchemaDataActor";
import { attributeSchemaDataActorMachine } from "@app/data/machine/actors/attributeSchemaDataActor";
import { alertsDataActorMachine } from "@app/data/machine/actors/alertsDataActor";
import { workspacesConfigDataActorMachine } from "@app/data/machine/actors/workspacesConfigDataActor";
import { rolesConfigDataActorMachine } from "@app/data/machine/actors/rolesConfigDataActor";
import { viewsConfigDataActorMachine } from "@app/data/machine/actors/viewsConfigDataActor";
import { generateAlertsConfigDataActorMachine } from "@app/data/machine/actors/alertsConfigDataActor";


export const configDataMachine = createMachine({
    id: "configDataMachine",
    context: {
        unknownDataActor: null,
        entitySchemaDataActor: null,
        attributeSchemaDataActor: null,
        alertsDataActor: null,
        configDataActorList: []
    },
    initial: "init",
    states: {
        init: {
            entry: "spawnInitialActors",
            always: "done",
        },
        done: {}
    }
},
{
    actions: {
        spawnInitialActors: assign({
            unknownDataActor: () => ({
                type: "unknownDataActor",
                ref: spawn(unknownDataActorMachine)
            }),
            entitySchemaDataActor: () => ({
                type: "entitySchemaDataActor",
                ref: spawn(entitySchemaDataActorMachine),
            }),
            attributeSchemaDataActor: () => ({
                type: "attributeSchemaDataActor",
                ref: spawn(attributeSchemaDataActorMachine)
            }),
            configDataActorList: () => {
                return [{
                    type: "config",
                    config: "workspaces",
                    ref: spawn(workspacesConfigDataActorMachine)
                },
                {
                    type: "config",
                    config: "roles",
                    ref: spawn(rolesConfigDataActorMachine)
                },
                {
                    type: "config",
                    config: "views",
                    ref: spawn(viewsConfigDataActorMachine)
                },
                {
                    type: "config",
                    config: "alerts",
                    ref: spawn(generateAlertsConfigDataActorMachine())
                }];
            }
        }),
    }
});
