/**
 * A machine for loading data and handling updates and creates of data optimistically.
 *
 * When data is updated or created the expected value of the operation is stored on the context of this machine
 * as part of the transientData. When data within this machine is accessed, the data and transientData arrays are combined
 * with the transientData object replacing the data object in position. This serves as an optimistic representation of the
 * server state of the data.
 *
 * While data is present in the transientData array, a poller runs on the machine getting the
 * latest server state of the data, reducing the list to the objects that match the transientData array.
 * Then, using the transientStamp value that is included with every update and create operation, a comparison is made
 * to check whether the loaded version of the transientData matches the version in the transientData.
 * This serves as confirmation that the changes made via the update or create operation is on the server state and
 * do not need to be rendered optimistically.
 *
 * At this point, any fresh data is removed from the transientData.
 * If there is no more transientData then the poller stops processing.
 *
 * @typedef SweftOptimisticDataMachine
 * @type {StateMachine<SweftOptimisticDataMachineContext, SweftOptimisticDataMachineEvent>}
 */

/**
 * @interface SweftOptimisticDataMachineContext
 * @prop {Array<SweftEvaluatedObject>} evaluatedData
 * @prop {Array<SweftEvaluatedObject>} transientData
 * @prop {number} startingPollingDelay
 * @prop {number} currentPollingDelay
 * @prop {number} pollingDelayMultiplier
 * @prop {number} maxPollingDelay
 * @prop {string} keyProperty
 */

/**
 * @typedef SweftOptimisticDataMachineEvent
 * @type {
 *  { type: 'LOAD_DATA' }
 *  | { type: 'CREATE_DATA'; object: Object; expectedCreatedObject: SweftEvaluatedObject }
 *  | { type: 'UPDATE_DATA'; object: SweftEvaluatedObject; expectedUpdateResult: { [field: string] : any }; fieldBeingUpdated: { [field: string] : any} }}
 */

/**
 * Interface for the optimistic data machine generator function props
 * @typedef {Object} SweftOptimisticDataMachineGeneratorProps
 * @property {string} type - type of data, used for identifying the machine in the xstate visualizer
 * @property {string} keyProperty - Unique property of data objects for processing
 * @property updateMachineOptions - Machine options for the spawned updateMachine
 * @property createMachineOptions - Machine options for the spawned createMachine
 * @property {SweftOptimisticDataMachineServices} services - Data machine services
 */

import { assign, createMachine, sendParent, sendUpdate } from "xstate";
import { optimisticDataActorMachineActionsBuilder } from "@app/data/machine/actors/optimisticDataActorMachine/actions";
import { optimisticDataActorMachineServicesBuilder } from "@app/data/machine/actors/optimisticDataActorMachine/services";
import { optimisticDataActorMachineGuardsBuilder } from "@app/data/machine/actors/optimisticDataActorMachine/guards";
import { FRESHNESS_PROCESSING_STRATEGY } from "@app/data/utils";

export const defaultTransientConfig = {
    transientProperty: "transientStamp",
    transientValueOnCreate: () => new Date().getTime(),
    transientValueOnUpdate: () => new Date().getTime(),
    evaluateTransientObject: true,
    freshnessProcessingStrategy: FRESHNESS_PROCESSING_STRATEGY.MERGE
};

export const optimisticDataActorMachineStaticInitialContext = {
    evaluatedData: [],
    transientData: [],
    transientChangeDataMap: {},
    loadDataActorList: [],
    createDataActorList: [],
    updateDataActorList: [],
    startingPollingDelay: 200,
    currentPollingDelay: 200,
    pollingDelayMultiplier: 2,
    maxPollingDelay: 2000,
    loadedProjectionAttributeList: [],
    latestAdditionalJsonLogicQueryObject: null,
    loadingDataError: null
};

/**
 * Generates a data machine for loading data and handling updates and creates of data optimistically.
 *
 * @type {function(SweftOptimisticDataMachineGeneratorProps):SweftOptimisticDataMachine}
 */
// eslint-disable-next-line complexity,max-lines-per-function
export const generateOptimisticDataMachine = ({ loadOnSpawn = true, entity, type, keyProperty, updateMachineOptions, createMachineOptions, deleteMachineOptions, loadMachineOptions, services, transientConfig = defaultTransientConfig, guardsOptions, noParent, projectionAttributeList, dataActorId, receiveEvaluatedBobj, entityFormulaPathMap, savedQuery }) => {
    if (!keyProperty || !type) {
        throw new Error("Need required properties for creating data machine.");
    }
    const { freshnessProcessingStrategy = FRESHNESS_PROCESSING_STRATEGY.MERGE } = transientConfig;

    const optimisticDataActorMachineActions = optimisticDataActorMachineActionsBuilder({ updateMachineOptions, createMachineOptions, deleteMachineOptions, loadMachineOptions, transientConfig });
    const optimisticDataActorMachineGuards = optimisticDataActorMachineGuardsBuilder({ guardsOptions });
    const optimisticDataActorMachineServices = optimisticDataActorMachineServicesBuilder({ servicesConfig: services, transientConfig });
    return createMachine(
        {
            predictableActionArguments: true,
            id: `${type}`,
            context: {
                dataActorId,
                keyProperty,
                entity,
                projectionAttributeList,
                receiveEvaluatedBobj,
                freshnessProcessingStrategy,
                entityFormulaPathMap,
                savedQuery,
                ...optimisticDataActorMachineStaticInitialContext
            },
            type: "parallel",
            states: {
                loader: {
                    initial: loadOnSpawn ? 'loadingData' : "idle",
                    states: {
                        loadingData: {
                            invoke: {
                                src: "loadService",
                                onDone: {
                                    target: "idle",
                                    actions: noParent ? ["setLoadedData"] : ["setLoadedData", sendParent({ type: `DATA_LOADED`, dataType: type })],
                                },
                                onError: {
                                    target: "idle"
                                }
                            },
                        },
                        idle: {},
                    },
                },
                poller: {
                    initial: "waiting",
                    states: {
                        idle: {},
                        waiting: {
                            after: {
                                POLLER_DELAY: [
                                    {
                                        target: "loadingFreshData",
                                        actions: "increasePollerDelay",
                                        cond: "notAtMaxPollerDelayAndHasTransientData"
                                    },
                                    {
                                        target: "loadingFreshData",
                                        cond: "hasTransientData"
                                    },
                                    {
                                        target: "idle",
                                        actions: "resetPollerDelay"
                                    }
                                ],
                            },
                        },
                        loadingFreshData: {
                            invoke: {
                                src: "loadDataFilteredForTransientData",
                                onDone: [
                                    {
                                        target: "waiting",
                                        cond: "freshDataIsPresent",
                                        actions: noParent ? ["processFreshData"] : ["processFreshData", sendParent((context, event) => ({ ...event, type: "ENTITY_FRESH_DATA_LOADED", entity, originator: dataActorId }))],
                                    },
                                    {
                                        target: "waiting",
                                    }
                                ],
                            },
                        },
                    },
                },
            },
            on: {
                LOAD_DATA: [{
                    cond: "hasAdditionalJsonLogicQueryObject",
                    actions: "spawnLoadDataActor"
                },{
                    cond: "hasPreFilterEntityMap",
                    actions: "spawnLoadDataActor"
                },{
                    cond: "hasViewOrAlertChanged",
                    actions: "spawnLoadDataActor"
                }, {
                    cond: "projectionNotLoadedYet",
                    actions: ["addProjectionsToAlreadyLoadedList", "spawnLoadDataActor"]
                }],
                CREATE_DATA: {
                    actions: ["generateTransientObjectToCreate"]
                },
                GENERATED_TRANSIENT_OBJECT_TO_CREATE: [
                    {
                        cond: "shouldAddObjectToTransientData",
                        target: "poller.waiting",
                        actions: noParent ? ["addObjectForCreatingToTransientData", "addTransientChangeObject", "spawnCreateDataActor"] : ["addObjectForCreatingToTransientData", "addTransientChangeObject", "spawnCreateDataActor", sendUpdate()]
                    },
                    {
                        target: "poller.waiting",
                        actions: noParent ? ["spawnCreateDataActor"] : ["spawnCreateDataActor", sendUpdate()],
                    }
                ],
                GENERATED_TRANSIENT_OBJECT_UPDATED: {
                    actions: ["addObjectForUpdatingToTransientData", "addTransientChangeObject"]
                },
                UPDATE_DATA: {
                    actions: ["generateTransientObjectToUpdate"]
                },
                UPDATE_GROUPED_DATA: {
                    actions: ["spawnUpdateGroupDataActor"]
                },
                GENERATED_TRANSIENT_OBJECT_TO_UPDATE: [
                    {
                        cond: "shouldOptimisticallyAddObject",
                        target: "poller.waiting",
                        actions: noParent ? ["addObjectOptimistically", "spawnUpdateDataActor"] : ["addObjectOptimistically", "spawnUpdateDataActor", sendUpdate()]
                    },
                    {
                        cond: "shouldAddObjectToTransientData",
                        target: "poller.waiting",
                        actions: noParent ? ["addObjectForUpdatingToTransientData", "addTransientChangeObject", "spawnUpdateDataActor"] : ["addObjectForUpdatingToTransientData", "addTransientChangeObject", "spawnUpdateDataActor", sendUpdate()],
                    },
                    {
                        target: "poller.waiting",
                        actions: noParent ? ["spawnUpdateDataActor"] : ["spawnUpdateDataActor", sendUpdate()]
                    }
                ],
                DELETE_DATA: {
                    actions: noParent ? ["deleteObjectFromData"] : ["deleteObjectFromData", sendUpdate()],
                },
                DATA_LOADED: [{
                    cond: "hasNewAdditionalJsonLogicQueryObject",
                    actions: noParent ? ["setLoadedData", "setNewAdditionalJsonLogicQueryObject"] : ["setLoadedData", "setNewAdditionalJsonLogicQueryObject", sendParent((context, event) => ({ type: "ENTITY_DATA_LOADED", entity, loadedData: event.loadedData, originator: dataActorId }))]
                }, {
                    actions: noParent ? ["addLoadedData"] : ["addLoadedData", sendParent((context, event) => ({ type: "ENTITY_DATA_LOADED", entity, loadedData: event.loadedData, originator: dataActorId }))]
                }],
                ERROR_LOADING_DATA: {
                    actions: ["setLoadingDataError", "stopDataLoaders"]
                },
                CLEAR_DATA_LOAD_ERROR: {
                    actions: 'clearLoadingDataError'
                },
                ENTITY_DATA_LOADED: {
                    actions: ["addLoadedData"],
                    cond: (context, event) => event.originator !== dataActorId && event.entity === entity
                },
                ENTITY_FRESH_DATA_LOADED: {
                    actions: ["processFreshData"],
                    cond: (context, event) => event.originator !== dataActorId && event.entity === entity
                },
                ADD_PROJECTION_ATTRIBUTES: {
                    actions: assign({
                        projectionAttributeList: (context, event) => {
                            const { attributeList = [] } = event;

                            if (Array.isArray(context?.projectionAttributeList)) {
                                const combinedList = [...context.projectionAttributeList, ...attributeList];
                                return Array.from(new Set(combinedList));
                            }

                            return Array.from(new Set(attributeList));
                        }
                    })
                },
                REMOVE_PROJECTION_ATTRIBUTES: {
                    actions: assign({
                        projectionAttributeList: (context, event) => {
                            const { attributeList = [] } = event;
                            return context?.projectionAttributeList?.filter((attribute) => !attributeList.includes(attribute));
                        }
                    })
                }
            }
        },
        {
            actions: optimisticDataActorMachineActions,
            services: optimisticDataActorMachineServices,
            guards: optimisticDataActorMachineGuards,
            delays: {
                POLLER_DELAY: (context) => context.currentPollingDelay
            }
        }
    );
};
