import { useMemo } from "react";
import { useFeature } from "flagged";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { findWorkspaceSpecialty } from "@app/specialty/utils/findWorkspaceSpecialty";
import { useDataActor } from "@app/data/useDataActor";
import { useAlertDataActorOptions } from "@app/contexts/useAlerts/useAlertDataActorOptions";

const useLegacyAlertStatus = ({ alert, withPreFilter = false }) => {
    const { alertWorkspaceObj } = alert;
    const { specialty } = useSpecialty();
    const dataActorOptions = useAlertDataActorOptions({ alert, withPreFilter });

    const {
        dataActorState: alertDataActorState,
        dataActorData: alertEntityData
    } = useDataActor({ dataActorOptions, spawnSource: "useLegacyAlertStatus" });

    const matchingObjectList = useMemo(() => {
        let objListForAlert = alertEntityData;
        if (specialty.isEnabled) {
            const workspaceSpecialty = findWorkspaceSpecialty({ specialty, workspace: alertWorkspaceObj });
            if (workspaceSpecialty) {
                objListForAlert = applyAdvancedFilters({ logic: workspaceSpecialty?.savedQueryObject, rows: objListForAlert });
            }
        }
        return objListForAlert;
    }, [alert?.savedQueryObject, alertEntityData, alertWorkspaceObj, specialty]);

    const isLoading = !alertDataActorState?.matches("loader.idle");
    const isFailed = !!(!alertDataActorState?.event?.data || alertDataActorState?.event?.data?.code === "ERR_NETWORK");

    return {
        isLoading,
        isFailed,
        savedQueryCount: matchingObjectList.length
    };
};

const useAlertSavedQueryStatus = ({ alert }) => {
    const { entity, savedQueryId, additionalJsonLogicQueryObject } = alert;
    const {
        dataActorState,
    } = useDataActor({ dataActorOptions: { dataActorType: "entitySavedQueryCounts", entity, additionalJsonLogicQueryObject }, spawnSource: "useAlertSavedQueryStatus", noSpawning: true });
    const isLoading = !dataActorState.matches("waitingBeforeNextFetch");
    const { savedQueryCountsByIdMap } = dataActorState?.context ?? {};
    const savedQueryCount = savedQueryCountsByIdMap?.[savedQueryId];
    return {
        isLoading,
        savedQueryCount,
        savedQueryId
    };
};

export const useAlertStatus = ({ alert, withPreFilter = false }) => {
    const shouldUseSavedQueryAlertsModule = useFeature('savedQueryAlerts');
    if (shouldUseSavedQueryAlertsModule) {
        return useAlertSavedQueryStatus({ alert });
    }
    return useLegacyAlertStatus({ alert, withPreFilter });
};
