import { useMemo } from "react";
import { findWorkspaceSpecialty } from "@app/specialty/utils/findWorkspaceSpecialty";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { useEntitlements } from "../useEntitlements";
import { useFeature } from "flagged";
import { getPreFilterEntityMapFromJsonLogic } from "@app/common/utils/getPreFilterEntityMapFromJsonLogic";


export const useAlertDataActorOptions = ({ alert, withPreFilter: _withPreFilter = false }) => {
    const enableSqlAlertFiltering = useFeature("enableSqlAlertFiltering");
    let withPreFilter = false;
    if (Boolean(enableSqlAlertFiltering) || _withPreFilter ) {
        withPreFilter = true;
    }
    const { entitiesFormulaMap } = useEntitlements();
    const { specialty } = useSpecialty();
    return useMemo(() => {
        if (!alert) {
            return null;
        }

        const { entity, projectionAttributeList: alertProjectionAttributeList, alertWorkspaceObj, savedQueryObject } = alert;

        let projectionAttributeList = alertProjectionAttributeList;

        if (specialty.isAvailable) {
            const workspaceSpecialty = findWorkspaceSpecialty({ specialty, workspace: alertWorkspaceObj }) ?? {};
            const { projectionAttributeList: specialtyProjectionAttributeList = [] } = workspaceSpecialty;
            projectionAttributeList = [...projectionAttributeList, ...specialtyProjectionAttributeList];
        }

        const uniqueProjectionAttributeList = Array.from(new Set(projectionAttributeList));
        const entityFormulaPathMap = entitiesFormulaMap[entity];
        const formulaForBackendEval = uniqueProjectionAttributeList.filter((projection) => entityFormulaPathMap?.[projection]);
        const { preFilterEntityMap, reducedSavedQueryObject } = getPreFilterEntityMapFromJsonLogic({ logic: savedQueryObject, entity, alertId: alert.configurationId });

        return {
            dataActorType: "entity",
            entity,
            type: `alert_${alert.displayName}`,
            projectionAttributeList: uniqueProjectionAttributeList,
            loadOnSpawn: true,
            receiveEvaluatedBobj: false,
            formulaForBackendEval,
            entityFormulaPathMap: entityFormulaPathMap,
            dataMappingModuleConfig: null,
            ...(withPreFilter
                ? {
                    dataActorSavedQueryObject: reducedSavedQueryObject,
                    preFilterEntityMap,
                    withPreFilter
                }
                : {
                    dataActorSavedQueryObject: savedQueryObject
                })
        };
    }, [alert, entitiesFormulaMap, specialty, withPreFilter]);
};
