// @ts-check
import request from './request';
import { baseConfig } from '@app/config/baseConfig';
import { base64Encode } from "@app/common/utils";
import { sortBy } from "lodash";
// @ts-ignore
const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}`;

export const SavedQueryService = {
    getSavedQueryListByReferenceIdList: async ({ idList = [] }) => {
        try {
            const response = await request({
                method: "GET",
                url: `${api}/saved-queries/references`
            });
            const savedQueryList = sortBy(response.data.response, (item) => new Date(item.modified).getTime()).reverse();
            return { data: savedQueryList.filter(({ referenceId }) => idList.includes(referenceId)) };
        } catch (e) {
            console.log("Error getting saved query reference list: ", e);
        }
    },
    getEntitySavedQueryCounts: async ({ entity }) => {
        const response = await request({
            method: "GET",
            url: `${api}/data/${entity}/saved-query-counts`
        });
        return response?.data;
    },
    getEntitySavedQueryCountsForSavedQueryIdList: async ({ entity, savedQueryIdList, additionalJsonLogicQueryObject }) => {
        let jlQueryParam = "";
        if (additionalJsonLogicQueryObject) {
            jlQueryParam = `&jlQuery=${base64Encode(JSON.stringify(additionalJsonLogicQueryObject))}`;
        }
        const response = await request({
            method: "GET",
            url: `${api}/data/${entity}/saved-query-counts?saved-query-id-list=${savedQueryIdList.join(",")}${jlQueryParam}`
        });
        return response?.data;
    }
};
