export const optimisticDataActorMachineGuardsBuilder = ({ guardsOptions }) => {
    const hasTransientData = (context) => {
        return context.transientData && context.transientData.length > 0;
    };
    const notAtMaxPollerDelayAndHasTransientData = (context) => {
        return (context.currentPollingDelay !== context.maxPollingDelay) && (context.transientData && context.transientData.length > 0);
    };

    const shouldAddObjectToTransientData = (context, event) => {
        const { transientObject } = event;
        if (guardsOptions?.shouldAddObjectToTransientDataCallback && typeof guardsOptions?.shouldAddObjectToTransientDataCallback === 'function') {
            return guardsOptions.shouldAddObjectToTransientDataCallback({ object: transientObject });
        }
        return true;
    };

    const freshDataIsPresent = (context, event) => event?.data?.freshData.length > 0;

    const projectionNotLoadedYet = (context, event) => {
        if (!event?.projectionAttributeList) {
            return true;
        }
        return event?.projectionAttributeList?.every((projection) => context?.loadedProjectionAttributeList?.includes(projection)) === false;
    };

    const hasAdditionalJsonLogicQueryObject = (context, event) => event?.additionalJsonLogicQueryObject || event?.data?.additionalJsonLogicQueryObject;

    const hasPreFilterEntityMap = (context, event) => event?.preFilterEntityMap || event?.data?.preFilterEntityMap;

    const hasViewOrAlertChanged = (context, event) => event?.projectionAttributeList !== context.projectionAttributeList;

    const hasNewAdditionalJsonLogicQueryObject = (context, event) => {
        if (context.latestAdditionalJsonLogicQueryObject) {
            const currentObjStr = JSON.stringify(context?.latestAdditionalJsonLogicQueryObject);
            const nextObjStr = JSON.stringify(event?.additionalJsonLogicQueryObject ?? {});
            return currentObjStr !== nextObjStr;
        }
        return !!event?.additionalJsonLogicQueryObject;
    };

    const hasDataLoadError = (context) => !!context.loadingDataError;

    const shouldOptimisticallyAddObject = (context, event) => event.optimisticUpdate === true;

    return {
        hasTransientData,
        notAtMaxPollerDelayAndHasTransientData,
        shouldAddObjectToTransientData,
        freshDataIsPresent,
        projectionNotLoadedYet,
        hasAdditionalJsonLogicQueryObject,
        hasNewAdditionalJsonLogicQueryObject,
        hasPreFilterEntityMap,
        hasViewOrAlertChanged,
        hasDataLoadError,
        shouldOptimisticallyAddObject
    };
};
