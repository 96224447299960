import { createMachine, sendParent } from "xstate";

export const generateLoadDataMachine = ({ context: machineContext, loadMachineOptions }) => {
    return createMachine({
        predictableActionArguments: true,
        id: `loadData`,
        context: machineContext,
        initial: "loadingData",
        states: {
            loadingData: {
                invoke: {
                    src: "loadService",
                    onDone: {
                        target: "dataLoaded",
                        actions: sendParent((context, event) => {
                            const loadedData = event?.data?.loadedData;
                            const isNoData = loadedData?.length === 0;
                            if (isNoData) {
                                return {
                                    type: `ERROR_LOADING_DATA`,
                                    errorObj: {
                                        errorType: "NoDataPresent"
                                    },
                                    loadDataMachineId: context.loadDataMachineId,
                                    additionalJsonLogicQueryObject: machineContext?.additionalJsonLogicQueryObject ?? null
                                };
                            }
                            if (loadedData.responseType === "error") {
                                const isDataSizeError = loadedData?.response?.data?.errorType === "Function.ResponseSizeTooLarge";
                                if (isDataSizeError) {
                                    return {
                                        type: `ERROR_LOADING_DATA`,
                                        errorObj: {
                                            errorType: "DataSizeLimitReached"
                                        },
                                        loadDataMachineId: context.loadDataMachineId,
                                        additionalJsonLogicQueryObject: machineContext?.additionalJsonLogicQueryObject ?? null
                                    };
                                }
                            }
                            const requestProjectionAttributeList = event?.data?.requestProjectionAttributeList;
                            const dataMappingModuleConfig = event?.data?.dataMappingModuleConfig;
                            return {
                                type: `DATA_LOADED`,
                                loadedData,
                                requestProjectionAttributeList,
                                loadDataMachineId: context.loadDataMachineId,
                                additionalJsonLogicQueryObject: machineContext?.additionalJsonLogicQueryObject ?? null,
                                dataMappingModuleConfig,
                            };
                        })
                    },
                    onError: {
                        target: "dataLoadError",
                        actions: sendParent((context, event) => {
                            const errorObj = event?.data;
                            const isTimeOutError = event?.data?.code === 'ERR_NETWORK';
                            if (isTimeOutError) {
                                return {
                                    type: `ERROR_LOADING_DATA`,
                                    errorObj: {
                                        errorType: "RequestTimedOut"
                                    },
                                    loadDataMachineId: context.loadDataMachineId,
                                    additionalJsonLogicQueryObject: machineContext?.additionalJsonLogicQueryObject ?? null
                                };
                            }
                            return {
                                type: `ERROR_LOADING_DATA`,
                                errorObj,
                                loadDataMachineId: context.loadDataMachineId,
                                additionalJsonLogicQueryObject: machineContext?.additionalJsonLogicQueryObject ?? null
                            };
                        })
                    }
                }
            },
            dataLoaded: {
                type: "final"
            },
            dataLoadError: {
                type: "final"
            }
        }
    }, {
        ...loadMachineOptions
    });
};
