import moment from "moment";

function _canOperateOnDateValues() {
    return Array.from(arguments).every((dateArg) => {
        return moment(dateArg, moment.ISO_8601).isValid();
    });
}

const _getDateValue = (a) => {
    let aAsDate;
    if (a instanceof Date) {
        aAsDate = a;
    } else {
        aAsDate = new Date(a);
    }
    return aAsDate;
};

function _getDateValues() {
    return Array.from(arguments).map(_getDateValue);
}

const _isDateBetween = (dateToCheck, startDate, endDate) => dateToCheck >= startDate && dateToCheck <= endDate;

const SweftDateEqual = (a, b) => {
    if (!_canOperateOnDateValues(moment(a), b)) {
        return false;
    }
    const [aAsDate, bAsDate] = _getDateValues(a, b);
    aAsDate.setHours(0, 0, 0, 0);
    bAsDate.setHours(0, 0, 0, 0);
    return aAsDate.getTime() === bAsDate.getTime();
};

const SweftDateNoEqual = (a, b) => {
    if (!_canOperateOnDateValues(moment(a), b)) {
        return false;
    }
    return !SweftDateEqual(a, b);
};

const SweftDateGreater = (a, b) => {
    if (!_canOperateOnDateValues(moment(a), b)) {
        return false;
    }
    const [aAsDate, bAsDate] = _getDateValues(a, b);
    return aAsDate.getTime() > bAsDate.getTime();
};

const SweftDateLess = (a, b) => {
    if (!_canOperateOnDateValues(moment(a), b)) {
        return false;
    }
    return !SweftDateGreater(a, b);
};

const SweftDateLessOrEqual = (a, b) => SweftDateLess(a, b) || SweftDateEqual(a, b);

const SweftDateGreaterOrEqual = (a, b) => SweftDateGreater(a, b) || SweftDateEqual(a, b);

const SweftDateBetween = (a, b, c) => {
    if (!_canOperateOnDateValues(moment(a), b, c)) {
        return false;
    }
    const [aAsDate, bAsDate, cAsDate] = _getDateValues(a, b, c);
    return _isDateBetween(aAsDate.getTime(), bAsDate.getTime(), cAsDate.getTime()) || _isDateBetween(aAsDate.getTime(), cAsDate.getTime(), bAsDate.getTime());
};

const SweftDateNotBetween = (a, b, c) => {
    if (!_canOperateOnDateValues(moment(a), b, c)) {
        return false;
    }
    const [aAsDate, bAsDate, cAsDate] = _getDateValues(a, b, c);
    return _isDateBetween(aAsDate.getTime(), bAsDate.getTime(), cAsDate.getTime()) === false && _isDateBetween(aAsDate.getTime(), cAsDate.getTime(), bAsDate.getTime()) === false;
};

const SweftContains = (a, b) => {
    if (!a || !b) {
        return false;
    }
    let searchElement = a;
    if (typeof searchElement === "string" && searchElement?.includes(',')) {
        searchElement = searchElement.split(',');
    }
    if (Array.isArray(searchElement)) {
        return searchElement?.some((item) => typeof b === 'string' && b?.toLowerCase().includes(item?.toLowerCase()));
    }
    return b?.toLowerCase()?.includes(searchElement?.toLowerCase());
};

/**
 * Returns true if a starts with b or a value in b
 * @param {string} a
 * @param {string|Array<string>} b
 * @returns {boolean}
 * @constructor
 */
const SweftStartsWith = (a, b,) => {
    if (!a || !b) {
        return false;
    }
    if (Array.isArray(b)) {
        return b.some((bValue) => a?.toLowerCase()?.startsWith(bValue.toLowerCase()));
    }
    if (typeof b === "string") {
        return a.toLowerCase().startsWith(b.toLowerCase());
    }
    return false;
};

const SweftNotStartsWith = (a, b) => {
    if (!a || !b) {
        return false;
    }
    return !SweftStartsWith(a, b);
};

const SweftEquals = (a, b) => a === b;

const SweftInList = (a, b) => {
    if (!a || !b) {
        return false;
    }

    let searchElement = a;
    if (searchElement?.includes(',')) {
        searchElement = searchElement.split(',');
    }
    if (searchElement?.includes(" ")) {
        searchElement = searchElement.split(' ');
    }
    if (Array.isArray(searchElement)) {
        return searchElement?.some((item) => {
            if (typeof b === 'string') {
                return b?.toLowerCase() === item?.toLowerCase();
            }
            return b === item || `${b}` === `${item}`;
        });
    }
    return false;
};

export const SweftOperators = {
    SweftDateEqual,
    SweftDateNoEqual,
    SweftDateLess,
    SweftDateLessOrEqual,
    SweftDateGreater,
    SweftDateGreaterOrEqual,
    SweftDateBetween,
    SweftDateNotBetween,
    SweftContains,
    SweftStartsWith,
    SweftNotStartsWith,
    SweftEquals,
    SweftInList
};


export const SweftOperatorsLabelMap = {
    SweftDateEqual: "Equal",
    SweftDateNoEqual: "Not",
    SweftDateLess: "Less",
    SweftDateLessOrEqual: "Less or Equal",
    SweftDateGreater: "Greater",
    SweftDateGreaterOrEqual: "Greater or Equal",
    SweftDateBetween: "Between",
    SweftDateNotBetween: "Not Between",
    SweftContains: "Contains",
    SweftStartsWith: "Starts With",
    SweftNotStartsWith: "Not Starts With",
    SweftEquals: "Equals",
    SweftInList: "In"
};
