// @ts-check

/**
 * The feature flag names as constants so we don't have to ever hardcode the string, just use the names object
 * The more descriptive (and explicit about what will be turned on), the better
 * @typedef {Record<string, string>} FlagNames
 * @example isFlagOn(flagNames.savedQueryAlerts) to avoid string literal problems for boolean flags
 * @note if we were using typescript, we could just use a enum flags list
 */
export const flagNames = {
    savedQueryAlerts: "savedQueryAlerts",
    xstateInspectorEnabled: "xstateInspectorEnabled",
    disabledAttributesHiddenForSuperAdmin: "disabledAttributesHiddenForSuperAdmin",
    enableMigrateExistingAlertsButton: "enableMigrateExistingAlertsButton",
    alertPreview: "alertPreview",
    "enableSqlAlertFiltering": "enableSqlAlertFiltering",
};

/**
 * Defaults states for flags, which may be booleans, strings, objects, etc
 * @example Copy and paste this into your localstorage to override on feature flags that you want on
 * @type {import("flagged").FeatureFlags}
 */
const flags = {
    "savedQueryAlerts": false,
    "xstateInspectorEnabled": false,
    "disabledAttributesHiddenForSuperAdmin": false,
    "alertPreview": false,
    "enableSqlAlertFiltering": false,
    "enableMigrateExistingAlertsButton": false,
};

/**
 * Get the feature flags, with localstorage overrides
 * @returns {Record<string, import("flagged").FeatureFlags>} The feature flags, with localstorage overrides
 */
export const getFeatureFlags = () => {
    const lflags = localStorage.getItem('flags');
    let localstorageFlags = {};
    try { // Prevent bad localstorage set from breaking the flags
        localstorageFlags = lflags ? JSON.parse(lflags) : {};
    } catch (e) {
        console.error("Error parsing localstorage flags!", e, lflags);
    }
    return { // localstorage goes second and thus overrides anything desired
        ...flags,
        ...localstorageFlags,
    };
};

/**
 * A wrapper to get the flag defaults, since the flags may eventually be set in server data
 * @returns {import("flagged").FeatureFlags} The default feature flags
 */
export const getDefaultFeatureFlags = () => {
    return flags;
};

/**
 *
 * @returns {Record<string, import("flagged").FeatureFlags> | {}} The localstorage feature flags
 */
export const getLocalFeatureFlags = () => {
    const lflags = localStorage.getItem('flags');
    let localstorageFlags = {};
    try { // Prevent bad localstorage set from breaking the flags
        localstorageFlags = lflags ? JSON.parse(lflags) : {};
    } catch (e) {
        console.error("Error parsing localstorage flags!", e, lflags);
    }
    return localstorageFlags;
};

export const setLocalFeatureFlags = (flagsObj) => {
    localStorage.setItem('flags', JSON.stringify(flagsObj));
};

export const setSingleLocalFeatureFlag = (flagName, flagValue) => {
    const flagsObj = getLocalFeatureFlags();
    flagsObj[flagName] = flagValue;
    localStorage.setItem('flags', JSON.stringify(flagsObj));
    return true;
};

const alwaysOnFlagList = [];

/**
 * For use with boolean feature flags only and in situations where the hook can't be used
 * @param {keyof flagNames} flagName A known feature flag named key
 * @returns boolean
 * @example isFlagOn(flagNames.savedQueryAlerts) to avoid string literal problems for boolean flags
 * @throws Error if the flagName is not defined so we don't get flagName typos
 */
export const isFlagOn = (flagName) => {
    const flagsObj = getFeatureFlags();
    if (!flagsObj || flagsObj[flagName] === undefined) {
        throw new Error(`Feature flag ${flagName} is not defined`);
    } else {
        return flagsObj[flagName] === true || alwaysOnFlagList.includes(flagName);
    }
};
