import { date_add, now } from "@app/grid/advancedFilters/advancedFilters";
import { isObject } from "@app/common/utils";
import { SweftError } from "@app/common/SweftError";


const jsonLogicToSweftOperatorMap = {
    "==": "SweftOperators.SweftEquals",
    "!=": "SweftOperators.SweftNotEquals",
    // "<": "",
    // "<=": "",
    ">": "SweftOperators.SweftGreater",
    ">=": "SweftOperators.SweftGreaterOrEqual",
    "SweftOperators.SweftContains": "SweftOperators.SweftContains",
    // "!": "SweftOperators.SweftIsNull",
    "!!": "SweftOperators.SweftNotEmpty",
    "in": "SweftOperators.SweftInList",
    "some": "SweftOperators.SweftInList",
    // "all": "SweftOperators.SweftAllInList",
    // "none": "SweftOperators.SweftIsNull",
    "SweftOperators.SweftStartsWith": "SweftOperators.SweftStartsWith",
    // "SweftOperators.SweftNotStartsWith": "",
    // "SweftOperators.SweftDateEqual": "",
    // "SweftOperators.SweftDateNoEqual": "",
    // "SweftOperators.SweftDateLess": "",
    "SweftOperators.SweftDateLessOrEqual": "SweftOperators.SweftDateLessOrEqual",
    // "SweftOperators.SweftDateGreater": "",
    // "SweftOperators.SweftDateGreaterOrEqual": "",
    // "SweftOperators.SweftDateBetween": "",
    // "SweftOperators.SweftDateNotBetween": ""
};

const negatedOperatorMap = {
    "SweftOperators.SweftContains": "SweftOperators.SweftNotContains",
};

const jsonLogicObjToPreFilterReducer = ({ jsonLogicObj, negated = false, grouped = false }) => {
    // eslint-disable-next-line complexity,max-statements
    return Object.keys(jsonLogicObj).reduce((_nestedQueryListForEntity, nextOperator) => {
        if (nextOperator === '!') {
            if (jsonLogicObj[nextOperator]?.and) {
                console.log("NOT Grouping operator, will not do.");
                return _nestedQueryListForEntity;
            }
            const nextQueryPreFilterList = jsonLogicObjToPreFilterReducer({ jsonLogicObj: jsonLogicObj[nextOperator], negated: true, grouped });
            return [
                ..._nestedQueryListForEntity,
                ...nextQueryPreFilterList
            ];
        }

        if (nextOperator === 'or') {
            try {
                const { entityPreFilterList: groupPreFilterList } = jsonLogicOjbListReducer({ jsonLogicObjList: jsonLogicObj[nextOperator], grouped: true });
                return [
                    ..._nestedQueryListForEntity,
                    {
                        operator: "SweftOperators.OrGroup",
                        list: groupPreFilterList
                    }
                ];
            } catch (e) {
                console.log("Could not get grouped OR pre-filter list: ", e);
                return _nestedQueryListForEntity;
            }
        }

        if (nextOperator === 'and') {
            console.log("AND operator, will not do.");
            return _nestedQueryListForEntity;
        }

        if (nextOperator === 'none') {
            console.log("none operator, will not do.");
            return _nestedQueryListForEntity;
        }

        if (nextOperator === 'some') {
            console.log("SOME operator, will not do.");
            return _nestedQueryListForEntity;
        }

        if (nextOperator === 'SweftOperators.SweftDateBetween') {
            console.log("SweftDateBetween operator, will not do.");
            return _nestedQueryListForEntity;
        }
        const operatorOptions = jsonLogicObj[nextOperator];

        let attribute; let value;

        if (['SweftOperators.SweftDateLessOrEqual'].includes(nextOperator)) {
            const { fieldObj, value: valueFromOperatorOptions } = operatorOptions.reduce((agg, nextOption) => {
                if (nextOption?.var) {
                    return {
                        ...agg,
                        fieldObj: nextOption
                    };
                }

                if (nextOption?.date_add) {
                    const dateAddOptions = nextOption.date_add;
                    const dateAddValue = date_add(now(), dateAddOptions[1], dateAddOptions[2]).getTime();
                    return {
                        ...agg,
                        value: dateAddValue
                    };
                }
                return agg;
            }, {});
            attribute = fieldObj.var;
            value = valueFromOperatorOptions;
        } else if (Array.isArray(operatorOptions)) {
            const { fieldObj, value: valueFromOperatorOptions } = operatorOptions.reduce((agg, nextOption) => {
                if (isObject(nextOption)) {
                    return {
                        ...agg,
                        fieldObj: nextOption
                    };
                }
                return {
                    ...agg,
                    value: nextOption
                };
            }, {});
            if (fieldObj.var.includes(".")) {
                if (grouped) {
                    throw new SweftError("Not top-level pre-filter");
                }
                return _nestedQueryListForEntity;
            }
            attribute = fieldObj.var;
            value = valueFromOperatorOptions;
        } else if (isObject(operatorOptions)) {
            if (operatorOptions?.var.includes(".")) {
                if (grouped) {
                    throw new SweftError("Not top-level pre-filter");
                }
                return _nestedQueryListForEntity;
            }
            attribute = operatorOptions.var;
        }


        if (!jsonLogicToSweftOperatorMap[nextOperator]) {
            console.log("No SweftOperator Mapping for JSON Logic Operator: ", nextOperator);
            return _nestedQueryListForEntity;
        }

        let operator = jsonLogicToSweftOperatorMap[nextOperator];
        if (operator === 'SweftOperators.SweftEquals' && value === null) {
            operator = 'SweftOperators.SweftIsNull';
        }

        if (operator === 'SweftOperators.SweftNotEquals' && value === null) {
            operator = 'SweftOperators.SweftIsNotNull';
        }

        if (nextOperator === '!!') {
            operator = 'SweftOperators.SweftNotEmpty';
            value = null;
        }

        if (negated) {
            if (!negatedOperatorMap[operator]) {
                console.log("No negated SweftOperator Mapping for JSON Logic Operator: ", operator);
                return _nestedQueryListForEntity;
            }
            operator = negatedOperatorMap[operator];
        }
        if (attribute.includes('_Values')) {
            attribute = attribute.replace("_Values", "");
        }


        return [
            ..._nestedQueryListForEntity,
            {
                operator,
                attribute,
                value
            }
        ];
    }, []);
};

const jsonLogicOjbListReducer = ({ jsonLogicObjList, grouped }) => {
    const { entityPreFilterList, reducedJsonLogicObjList } = jsonLogicObjList.reduce(({ entityPreFilterList: currentEntityPreFilterList, reducedJsonLogicObjList: currentReducedJsonLogicObjList }, nextQueryObj) => {
        const nextQueryPreFilterList = jsonLogicObjToPreFilterReducer({ jsonLogicObj: nextQueryObj, grouped });
        console.log({ nextQueryPreFilterList });
        let nextReducedJsonLogicObjList = [
            ...currentReducedJsonLogicObjList,
        ];

        if (nextQueryPreFilterList.length === 0) {
            nextReducedJsonLogicObjList = [
                ...nextReducedJsonLogicObjList,
                nextQueryObj
            ];
        }

        return {
            entityPreFilterList: [

                ...currentEntityPreFilterList,
                ...nextQueryPreFilterList
            ],
            reducedJsonLogicObjList: nextReducedJsonLogicObjList
        };
    }, {
        entityPreFilterList: [],
        reducedJsonLogicObjList: []
    });
    return { entityPreFilterList, reducedJsonLogicObjList };
};

export const getPreFilterEntityMapFromJsonLogic = ({ logic, entity, alertId }) => {
    try {
        const { entityPreFilterList, reducedJsonLogicObjList } = jsonLogicOjbListReducer({ jsonLogicObjList: logic.and });

        let reducedSavedQueryObject = null;

        if (reducedJsonLogicObjList.length > 0) {
            reducedSavedQueryObject = {
                logic: {
                    and: reducedJsonLogicObjList
                }
            };
        }
        return {
            preFilterEntityMap: {
                [entity]: entityPreFilterList
            },
            reducedSavedQueryObject
        };
    } catch (e) {
        console.log("Error getting pre filter entity map from json logic ", e);
        console.log("relevant logic: ", { logic, alertId });
        return {
            preFilterEntityMap: {},
            reducedSavedQueryObject: {}
        };
    }
};
