import { useAuth } from "@app/auth/context/useAuth";
import { useEntitlements } from "@app/contexts/useEntitlements";
import { useMemo } from "react";


export const getCurrentUserRole = ({ roleList, user, isAdmin }) => {
    const roleIdToLookFor = isAdmin ? "admin" : user?.currentRole;
    return roleList?.find((role) => role?.roleId?.toLowerCase() === decodeURIComponent(roleIdToLookFor.toLowerCase())
    ) ?? null;
};

export const useCurrentUserRole = () => {
    const { user, isAdmin } = useAuth();
    const { roleList } = useEntitlements();
    const currentUserRole = useMemo(() => getCurrentUserRole({ user, roleList, isAdmin }), [user, roleList, isAdmin]);
    return {
        currentUserRole
    };
};
