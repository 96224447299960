import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { findInList, getUniqueObjList, isObject } from "@app/common/utils";
/*eslint max-lines-per-function: "Warn" */
/*eslint max-lines: "Warn" */
const CreativePlanContext = createContext();

export const useCreativePlan = () => useContext(CreativePlanContext);

export const CreativePlanWrapper = ({ children }) => {
    const creativePlanConfigIsValid = ({ creativePlanConfig = {} } = {}) => {
        const {
            productProjectAttribute,
            projectDeliverableAttribute,
            deliverableTaskListAttribute,
            productCategoryAttribute,
            categoryShotAnglesAttribute,
            defaultTaskList = [],
            taskNameAttribute,
            taskDeliverableAttribute,
            shotAngleAttributeToDeliverableAttributeMap,
            projectProductAttribute,
            deliverableProjectAttribute,
            productToProjectAttributeMap
        } = creativePlanConfig;

        if ([productProjectAttribute,
            projectDeliverableAttribute,
            deliverableTaskListAttribute,
            productCategoryAttribute,
            categoryShotAnglesAttribute,
            defaultTaskList,
            taskNameAttribute,
            taskDeliverableAttribute,
            shotAngleAttributeToDeliverableAttributeMap,
            projectProductAttribute,
            deliverableProjectAttribute,
            productToProjectAttributeMap
        ].some((attributeVal) => !attributeVal)) {
            return false;
        }

        return Array.isArray(defaultTaskList);
    };

    const generateCreativeAssetFilename = ({ index, nextShotAngleObj, styleIdAttribute, deliverableCreativeAssetFilenameSortOrderAttribute }) => {
        if (index === 0) {
            return `${styleIdAttribute}`;
        }
        return `${styleIdAttribute}-${nextShotAngleObj[deliverableCreativeAssetFilenameSortOrderAttribute]}`;
    };

    const generateDeliverablesAndTasksToCreate = ({ productObj, projectId, shotAnglesFromProductCategory, creativePlanConfig, deliverableTaskListAttributeObj, projectDeliverableAttributeObj }) => {
        const { defaultTaskList, taskNameAttribute, taskDeliverableAttribute, shotAngleAttributeToDeliverableAttributeMap, deliverableProjectAttribute, deliverableTaskListAttribute, deliverableCreativeAssetFilenameAttribute, deliverableCreativeAssetFilenameStyleIdAttribute, deliverableCreativeAssetFilenameSortOrderAttribute, taskIsCompleteAttribute } = creativePlanConfig;

        return shotAnglesFromProductCategory.sort((angleA, angleB) => +angleA[deliverableCreativeAssetFilenameSortOrderAttribute] - +angleB[deliverableCreativeAssetFilenameSortOrderAttribute]).reduce((deliverablesAndTasksToCreate, nextShotAngleObj, index) => {
            const deliverableId = uuidv4();

            const taskListForDeliverable = defaultTaskList.map((nextTask) => {
                return {
                    id: uuidv4(),
                    entity: deliverableTaskListAttributeObj?.relation,
                    createdDate: new Date().getTime(),
                    [taskNameAttribute]: nextTask,
                    [taskIsCompleteAttribute]: false,
                    [taskDeliverableAttribute]: deliverableId
                };
            });

            const deliverableAttributesFromShotAngle = Object.keys(shotAngleAttributeToDeliverableAttributeMap).reduce((deliverableAttributesFromShotAngleObj, nextShotAngleAttribute) => {
                return {
                    ...deliverableAttributesFromShotAngleObj,
                    [shotAngleAttributeToDeliverableAttributeMap[nextShotAngleAttribute]]: nextShotAngleObj[nextShotAngleAttribute]
                };
            }, {});

            const styleIdAttribute = productObj[deliverableCreativeAssetFilenameStyleIdAttribute];
            const deliverable = {
                id: deliverableId,
                createdDate: new Date().getTime(),
                entity: projectDeliverableAttributeObj?.relation,
                [deliverableProjectAttribute]: projectId,
                [deliverableTaskListAttribute]: taskListForDeliverable.reduce((taskIdList, nextTaskObj) => ([...taskIdList, nextTaskObj.id]), []),
                [deliverableCreativeAssetFilenameAttribute]: generateCreativeAssetFilename({ index, nextShotAngleObj, styleIdAttribute, deliverableCreativeAssetFilenameSortOrderAttribute }),
                ...deliverableAttributesFromShotAngle
            };
            return {
                deliverablesToCreate: [
                    ...deliverablesAndTasksToCreate.deliverablesToCreate,
                    deliverable
                ],
                tasksToCreate: [
                    ...deliverablesAndTasksToCreate.tasksToCreate,
                    ...taskListForDeliverable
                ]
            };
        }, {
            deliverablesToCreate: [],
            tasksToCreate: []
        });
    };

    const generateProjectToCreate = ({ productObj, projectId, deliverablesToCreate, productProjectAttributeObj, creativePlanConfig }) => {
        const { productToProjectAttributeMap, projectProductAttribute, projectDeliverableAttribute } = creativePlanConfig;
        const deliverableIdListForProject = deliverablesToCreate.reduce((deliverableIdList, nextDeliverableObj) => ([...deliverableIdList, nextDeliverableObj.id]), []);

        const projectAttributeFromProductAttributeMap = Object.keys(productToProjectAttributeMap).reduce((projectAttributesFromProductMap, nextProductAttribute) => {
            return {
                ...projectAttributesFromProductMap,
                [productToProjectAttributeMap[nextProductAttribute]]: productObj?.[nextProductAttribute]
            };
        }, {});


        const projectToCreate = {
            id: projectId,
            createdDate: new Date().getTime(),
            entity: productProjectAttributeObj?.relation,
            [projectProductAttribute]: productObj?.id,
            [projectDeliverableAttribute]: deliverableIdListForProject,
            ...projectAttributeFromProductAttributeMap
        };
        return { projectToCreate };
    };

    const generateProjectDeliverablesAndTasks = ({
        projectDeliverableAttributeObj,
        productProjectAttributeObj,
        deliverableTaskListAttributeObj,
        creativePlanConfig,
        shotAnglesFromProductCategory,
        productObj,
        projectId
    }) => {
        const { deliverablesToCreate, tasksToCreate } = generateDeliverablesAndTasksToCreate({
            productObj,
            projectId,
            shotAnglesFromProductCategory,
            deliverableTaskListAttributeObj,
            projectDeliverableAttributeObj,
            creativePlanConfig
        });
        const { projectToCreate } = generateProjectToCreate({
            productObj,
            projectId,
            deliverablesToCreate,
            productProjectAttributeObj,
            creativePlanConfig
        });

        return {
            deliverablesToCreate,
            tasksToCreate,
            projectToCreate,
            productObj
        };
    };

    const createProjectDeliverablesAndTasks = ({
        tasksToCreate,
        deliverablesToCreate,
        projectToCreate,
        tasksEntityDataActorSend,
        deliverablesEntityDataActorSend,
        projectsEntityDataActorSend,
    }) => {
        tasksToCreate.forEach((taskObj) => {
            tasksEntityDataActorSend({
                type: "CREATE_DATA",
                object: { ...taskObj },
                expectedCreatedObject: {
                    ...taskObj
                }
            });
        });
        deliverablesToCreate.forEach((deliverableObj) => {
            deliverablesEntityDataActorSend({
                type: "CREATE_DATA",
                object: { ...deliverableObj },
                expectedCreatedObject: {
                    ...deliverableObj
                }
            });
        });

        projectsEntityDataActorSend({
            type: "CREATE_DATA",
            object: { ...projectToCreate },
            expectedCreatedObject: {
                ...projectToCreate
            }
        });
    };

    const generateCreativePlanObjects = async ({
        productObj,
        creativePlanConfig,
        deliverableTaskListAttributeObj,
        projectDeliverableAttributeObj,
        productProjectAttributeObj,
        tasksEntityDataActorSend,
        deliverablesEntityDataActorSend,
        projectsEntityDataActorSend,
        productEntityDataActorSend,
        onValueChange,
    }) => {
        const { productCategoryAttribute, categoryShotAnglesAttribute, productProjectAttribute, projectDeliverableAttribute, deliverableTaskListAttribute } = creativePlanConfig;
        const productCategory = productObj?.[productCategoryAttribute];
        let shotAnglesFromProductCategory = productCategory?.[categoryShotAnglesAttribute] ?? [];

        if (!Array.isArray(shotAnglesFromProductCategory)) {
            shotAnglesFromProductCategory = [];
        }
        const projectId = uuidv4();
        shotAnglesFromProductCategory = getUniqueObjList({ objectList: shotAnglesFromProductCategory, keyProperty: "id" });

        if (Array.isArray(productObj) && productObj.length > 0) {
            productObj.forEach(async (node) => {
                const projectId = uuidv4();
                const productCategory = node?.data[productCategoryAttribute];
                let shotAnglesFromProductCategory = productCategory?.[categoryShotAnglesAttribute] ?? [];

                const { projectToCreate, deliverablesToCreate, tasksToCreate } = generateProjectDeliverablesAndTasks({
                    projectDeliverableAttributeObj,
                    productProjectAttributeObj,
                    deliverableTaskListAttributeObj,
                    creativePlanConfig,
                    shotAnglesFromProductCategory,
                    projectId,
                    productObj: node.data
                });

                createProjectDeliverablesAndTasks({ tasksEntityDataActorSend, deliverablesEntityDataActorSend, projectsEntityDataActorSend, projectToCreate, deliverablesToCreate, tasksToCreate });
                const onValueChangeData = onValueChange(node.data);


                const deliverableAndTaskList = deliverablesToCreate.map((deliverable) => {
                    return {
                        ...deliverable,
                        [deliverableTaskListAttribute]: tasksToCreate.filter(task => task.deliverable === deliverable.id)
                    };
                });

                let fieldBeingUpdated = {};
                if (onValueChange) {
                    fieldBeingUpdated = {
                        [onValueChangeData.field]: onValueChangeData.value,
                        [productProjectAttribute]: projectId
                    };
                } else {
                    fieldBeingUpdated[productProjectAttribute] = projectId;
                }

                let expectedUpdateResultObj = {};
                if (onValueChange) {
                    expectedUpdateResultObj = {
                        [onValueChangeData.field]: onValueChangeData.value,
                        [productProjectAttribute]: {
                            ...projectToCreate,
                            [projectDeliverableAttribute]: deliverableAndTaskList
                        }

                    };
                } else {
                    expectedUpdateResultObj[productProjectAttribute] = {
                        ...projectToCreate,
                        [projectDeliverableAttribute]: deliverableAndTaskList
                    };
                }

                productEntityDataActorSend({
                    type: "UPDATE_DATA",
                    object: {
                        ...node.data,
                        fieldBeingUpdated,
                    },
                    fieldBeingUpdated,
                    expectedUpdateResult: expectedUpdateResultObj,
                    optimisticUpdate: true,
                });
            });
        }

        if (!Array.isArray(productObj)) {
            const { projectToCreate, deliverablesToCreate, tasksToCreate } = generateProjectDeliverablesAndTasks({
                projectDeliverableAttributeObj,
                productProjectAttributeObj,
                deliverableTaskListAttributeObj,
                creativePlanConfig,
                shotAnglesFromProductCategory,
                projectId,
                productObj
            });

            createProjectDeliverablesAndTasks({ tasksToCreate, deliverablesToCreate, projectToCreate, tasksEntityDataActorSend, deliverablesEntityDataActorSend, projectsEntityDataActorSend });

            console.log("projectToCreate to create", { projectToCreate });

            const onValueChangeData = onValueChange(productObj);

            const deliverableAndTaskList = deliverablesToCreate.map((deliverable) => {
                return {
                    ...deliverable,
                    [deliverableTaskListAttribute]: tasksToCreate.filter(task => task.deliverable === deliverable.id)
                };
            });

            let fieldBeingUpdated = {};
            if (onValueChange) {
                fieldBeingUpdated = {
                    [onValueChangeData.field]: onValueChangeData.value,
                    [productProjectAttribute]: projectId
                };
            } else {
                fieldBeingUpdated[productProjectAttribute] = projectId;
            }

            let expectedUpdateResultObj = {};
            if (onValueChange) {
                expectedUpdateResultObj = {
                    [onValueChangeData.field]: onValueChangeData.value,
                    [productProjectAttribute]: {
                        ...projectToCreate,
                        [projectDeliverableAttribute]: deliverableAndTaskList
                    }

                };
            } else {
                expectedUpdateResultObj[productProjectAttribute] = {
                    ...projectToCreate,
                    [projectDeliverableAttribute]: deliverableAndTaskList
                };
            }

            productEntityDataActorSend({
                type: "UPDATE_DATA",
                object: {
                    ...productObj,
                    fieldBeingUpdated,
                },
                fieldBeingUpdated,
                expectedUpdateResult: expectedUpdateResultObj,
                optimisticUpdate: true,
            });

            console.log("prodToUpdate", {
                ...productObj,
                [productProjectAttribute]: projectId
            });
        }
    };

    const contextValue = useMemo(() => ({
        generateCreativeAssetFilename,
        generateCreativePlanObjects,
        generateDeliverablesAndTasksToCreate,
        generateProjectToCreate,
        creativePlanConfigIsValid
    }), [generateCreativeAssetFilename, generateCreativePlanObjects, generateDeliverablesAndTasksToCreate, generateProjectToCreate, creativePlanConfigIsValid]);

    return (
        <CreativePlanContext.Provider value={contextValue}>{children}</CreativePlanContext.Provider>
    );
};
