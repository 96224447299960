// @ts-check
import React from "react";
import { Avatar, Image } from 'antd';


/**
 * Display an avatar for a user, or a blank when no user available
 * @param {*} param0
 * @returns
 */
export const AvatarArea = ({ user, highlightColor, className, ...rest }) => (
    <>
        <Avatar
            className={`bg-${highlightColor ?? 'teal'}-400 text-${highlightColor ?? 'teal'}-50 ${className ?? ''}`}
            src={user?.avatar}
            {...rest}>
            {user?.firstName?.charAt(0)}
        </Avatar>
    </>
);