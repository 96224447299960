import { assign, actions } from "xstate";
import { evaluateBobj, evaluateBobjList, evaluateBobjListWithFormulaList, FRESHNESS_PROCESSING_STRATEGY } from "@app/data/utils";
import { mergeBobj } from "./utils";
const { pure, stop } = actions;

// eslint-disable-next-line max-lines-per-function
export const staticActions = {
    addObjectForCreatingToTransientData: assign({
        transientData: (context, event) => {
            const { transientObject } = event;
            return [...context.transientData,
                {
                    object: transientObject
                }
            ];
        }
    }),
    addObjectForUpdatingToTransientData: assign({
        transientData: (context, event) => {
            const { keyProperty, transientData } = context;
            const { transientObject } = event;
            const existingTransientObjectWrapper = transientData.find(({ object: dataObj }) => dataObj[keyProperty] === transientObject[keyProperty]);
            if (existingTransientObjectWrapper) {
                const restOfTheTransientData = transientData.filter(({ object: dataObj }) => dataObj[keyProperty] !== transientObject[keyProperty]);
                return [
                    ...restOfTheTransientData,
                    {
                        object: mergeBobj({ currentBobj: existingTransientObjectWrapper.object, newBobj: transientObject, keyProperty })
                    }
                ];
            }
            return [
                ...transientData,
                { object: transientObject }
            ];
        }
    }),
    addTransientChangeObject: assign({
        transientChangeDataMap: (context, event) => {
            const { keyProperty, transientChangeDataMap } = context;
            const { transientChangeObject } = event;
            const keyValue = transientChangeObject[keyProperty];
            const existingTransientChangeData = transientChangeDataMap[keyValue];
            if (existingTransientChangeData) {
                return {
                    ...transientChangeDataMap,
                    [keyValue]: {
                        object: mergeBobj({ currentBobj: existingTransientChangeData.object, newBobj: transientChangeObject, keyProperty })
                    }
                };
            }
            return {
                ...transientChangeDataMap,
                [keyValue]: {
                    object: transientChangeObject
                }
            };
        }
    }),
    addObjectOptimistically: assign({
        evaluatedData: (context, event) => {
            const { keyProperty, evaluatedData } = context;
            const { transientObject } = event;
            return evaluatedData.map((obj) => {
                if (obj[keyProperty] !== transientObject[keyProperty]) {
                    return obj;
                }
                return transientObject;
            });
        }
    }),
    setLoadedData: assign({
        evaluatedData: (context, event) => {
            const { receiveEvaluatedBobj, entityFormulaPathMap } = context;
            const dataToSet = event?.data ? event?.data?.loadedData : event?.loadedData;
            const requestProjectionAttributeList = event?.data ? event?.data?.requestProjectionAttributeList : event?.requestProjectionAttributeList;
            const dataMappingModuleConfig = event?.data ? event?.data?.dataMappingModuleConfig : event?.dataMappingModuleConfig;

            if (receiveEvaluatedBobj) {
                return dataToSet;
            }

            if (!entityFormulaPathMap) {
                return evaluateBobjList({ bobjList: dataToSet });
            }

            const formulaToEvaluateList = requestProjectionAttributeList.reduce((formulaList, nextProjection) => {
                const projectionFormulaObj = entityFormulaPathMap[nextProjection];
                if (!projectionFormulaObj) {
                    return formulaList;
                }
                return [
                    ...formulaList,
                    projectionFormulaObj
                ];
            }, []);

            return evaluateBobjListWithFormulaList({ bobjList: dataToSet, formulaList: formulaToEvaluateList, dataMappingModuleConfig });
        },
        loadDataActorList: (context, event) => {
            return context.loadDataActorList.filter((dataActorObj) => dataActorObj.loadDataMachineId !== event?.loadDataMachineId);
        }
    }),
    addLoadedData: assign({
        evaluatedData: (context, event) => {
            const { keyProperty, receiveEvaluatedBobj, entityFormulaPathMap } = context;
            const requestProjectionAttributeList = event?.data ? event?.data?.requestProjectionAttributeList ?? [] : event?.requestProjectionAttributeList ?? [];
            const dataMappingModuleConfig = event?.data ? event?.data?.dataMappingModuleConfig : event?.dataMappingModuleConfig;

            if (event.loadedData?.responseType === "error") {
                return event.loadedData;
            }

            const formulaToEvaluateList = requestProjectionAttributeList.reduce((formulaList, nextProjection) => {
                const projectionFormulaObj = entityFormulaPathMap?.[nextProjection] ?? null;
                if (!projectionFormulaObj) {
                    return formulaList;
                }
                return [
                    ...formulaList,
                    projectionFormulaObj
                ];
            }, []);

            if (context.evaluatedData.length === 0) {
                return !receiveEvaluatedBobj && !entityFormulaPathMap ? evaluateBobjList({ bobjList: event.loadedData }) : evaluateBobjListWithFormulaList({ bobjList: event.loadedData, formulaList: formulaToEvaluateList, dataMappingModuleConfig });
            }
            const newCombinedData = event.loadedData.map((newBobj) => {
                const currentBobj = context.evaluatedData.find((curObj) => curObj[keyProperty] === newBobj[keyProperty]);
                return mergeBobj({ currentBobj, newBobj, keyProperty });
            });
            return !receiveEvaluatedBobj && !entityFormulaPathMap ? evaluateBobjList({ bobjList: newCombinedData }) : evaluateBobjListWithFormulaList({ bobjList: newCombinedData, formulaList: formulaToEvaluateList, dataMappingModuleConfig });
        },
        loadDataActorList: (context, event) => {
            return context.loadDataActorList.filter((dataActorObj) => dataActorObj.loadDataMachineId !== event?.loadDataMachineId);
        }
    }),
    processFreshData: assign((context, event) => {
        const { transientData, evaluatedData, keyProperty, freshnessProcessingStrategy, receiveEvaluatedBobj, transientChangeDataMap } = context;
        const newEvaluatedData = evaluatedData;
        const newTransientData = transientData;
        let newTransientChangeDataMap = transientChangeDataMap;
        const freshData = event.data.freshData;
        // Get the fresh data that will be moved from transientData to data
        freshData.forEach((freshDataObj) => {
            const evaluatedFreshDataObj = !receiveEvaluatedBobj ? evaluateBobj({ bobj: freshDataObj }) : freshDataObj;
            const foundTransientDataObjIndex = newTransientData.findIndex(
                ({ object: transientDataObj }) => evaluatedFreshDataObj[keyProperty] === transientDataObj[keyProperty]
            );
            let currentObject = evaluatedFreshDataObj;
            let transientObj;
            if (foundTransientDataObjIndex > -1) {
                transientObj = newTransientData[foundTransientDataObjIndex].object;
                newTransientData.splice(foundTransientDataObjIndex, 1);
                const keyValue = transientObj[keyProperty];
                const {
                    // eslint-disable-next-line no-unused-vars
                    [keyValue]: _removedValue,
                    ...restOfNewTransientChangeDataMap
                } = newTransientChangeDataMap;
                newTransientChangeDataMap = restOfNewTransientChangeDataMap;
            }
            const indexOnData = evaluatedData.findIndex((bobj) => bobj[keyProperty] === currentObject[keyProperty]);
            if (indexOnData > -1) {
                if (freshnessProcessingStrategy === FRESHNESS_PROCESSING_STRATEGY.REPLACE) {
                    newEvaluatedData[indexOnData] = currentObject;
                } else if (freshnessProcessingStrategy === FRESHNESS_PROCESSING_STRATEGY.KEEP_TRANSIENT) {
                    newEvaluatedData[indexOnData] = { ...transientObj };
                } else {
                    currentObject = mergeBobj({ currentBobj: transientObj, newBobj: currentObject });
                    // Assume default freshness processing strategy of merge
                    newEvaluatedData[indexOnData] = mergeBobj({ currentBobj: newEvaluatedData[indexOnData], newBobj: currentObject, keyProperty });
                }
            } else {
                newEvaluatedData.unshift(currentObject);
            }
        });

        return {
            evaluatedData: newEvaluatedData,
            transientData: newTransientData,
            transientChangeDataMap: newTransientChangeDataMap
        };
    }),
    resetPollerDelay: assign({
        currentPollingDelay: (context) => context.startingPollingDelay
    }),
    increasePollerDelay: assign({
        currentPollingDelay: (context) => {
            const nextPollingDelay = context.currentPollingDelay * context.pollingDelayMultiplier;
            if (nextPollingDelay > context.maxPollingDelay) {
                return context.maxPollingDelay;
            }
            return nextPollingDelay;
        }
    }),
    updateObjectInTransientData: assign({
        transientData: (context, event) => {
            const { keyProperty } = context;
            const { object } = event;
            return context.transientData.map((transientDataObj) => {
                const { object: transientObject } = transientDataObj;
                if (object[keyProperty] !== transientObject[keyProperty]) {
                    return transientDataObj;
                }
                return {
                    ...transientDataObj,
                    object
                };
            });
        }
    }),
    addProjectionsToAlreadyLoadedList: assign({
        loadedProjectionAttributeList: (context, event) => Array.from(new Set([...context.loadedProjectionAttributeList, ...(event?.projectionAttributeList ?? [])]))
    }),
    setNewAdditionalJsonLogicQueryObject: assign({
        latestAdditionalJsonLogicQueryObject: (context, event) => event?.additionalJsonLogicQueryObject
    }),
    setLoadingDataError: assign({
        loadingDataError: (context, event) => {
            const { errorObj } = event;
            return errorObj;
        }
    }),
    stopDataLoaders: pure((context) => {
        return [
            ...context.loadDataActorList.map((loadDataActorObj) => stop(loadDataActorObj.loadDataMachineId)),
            assign({
                loadDataActorList: () => []
            })
        ];
    }),
    clearLoadingDataError: assign({
        loadingDataError: () => null
    })
};
